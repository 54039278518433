<template>
  <section class="container970" v-loading="loading">
    <el-card>
      <div class="clubTop">
        <h3>Contact our Club100 members</h3>
        <el-image :src="backgroundImage"></el-image>
      </div>
      <div class="clubP" v-if="Platinum.length">
        <p>PLATINUM</p>
        <div class="bordeColor">
          <el-table :data="Platinum" :show-header="false" style="width: 100%">
            <el-table-column prop="company_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="real_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="position" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.position || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="darkCol"
              prop="email"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clubG" v-if="Gold.length">
        <p>GLOD</p>
        <div class="bordeColor">
          <el-table :data="Gold" :show-header="false" style="width: 100%">
            <el-table-column prop="company_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="real_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="position" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.position || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="darkCol"
              show-overflow-tooltip
              prop="email"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clubS" v-if="Silver.length">
        <p>SILVER</p>
        <div class="bordeColor">
          <el-table :data="Silver" :show-header="false" style="width: 100%">
            <el-table-column prop="company_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="real_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="position" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.position || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="darkCol"
              show-overflow-tooltip
              prop="email"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clubB" v-if="Bronze.length">
        <p>BRONZE</p>
        <div class="bordeColor">
          <el-table :data="Bronze" :show-header="false" style="width: 100%">
            <el-table-column prop="company_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="real_name" show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="position" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.position || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              class-name="darkCol"
              show-overflow-tooltip
              prop="email"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      backgroundImage: require("@/assets/images/group/club.png"),
      Platinum: [],
      Gold: [],
      Silver: [],
      Bronze: [],
      loading: false
    };
  },
  components: {},

  created() {
    this.getClub100Contact();
  },

  methods: {
    async getClub100Contact() {
      this.loading = true;
      let res = await this.$http.getClub100Contact({});
      if (res.status == 200) {
        this.Platinum = res.data.Platinum;
        this.Gold = res.data.Gold;
        this.Silver = res.data.Silver;
        this.Bronze = res.data.Bronze;
      }
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.clubTop {
  display: flex;
  justify-content: space-between;
  h3 {
    color: #50b5ff;
    font-size: 40px;
  }
  .el-image {
    width: 280px;
  }
}
.clubP,
.clubG,
.clubS,
.clubB {
  p {
    height: 20px;
    font-size: 16px;
    margin: 30px 0 0;
    font-weight: 500;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
  .bordeColor {
    padding: 2px;
    border-radius: 5px;
    .el-table {
      color: #666;
      font-weight: 300;
    }
    ::v-deep .darkCol {
      color: #333;
      font-weight: 500;
    }
  }
}
.clubP {
  p {
    width: 65px;
    background-image: linear-gradient(120deg, #dae4ec, #f4f7f8 50%, #dae4ec);
  }
  .bordeColor {
    background-image: linear-gradient(120deg, #dae4ec, #f4f7f8 50%, #dae4ec);
  }
}
.clubG {
  p {
    width: 50px;
    background-image: linear-gradient(150deg, #fbe67b, #fdfae1 50%, #fbe67b);
  }
  .bordeColor {
    background-image: linear-gradient(120deg, #fbe67b, #fdfae1 50%, #fbe67b);
  }
}
.clubS {
  p {
    width: 55px;
    background-image: linear-gradient(150deg, #bfbfbd, #f1f1f0 50%, #bfbfbd);
  }
  .bordeColor {
    background-image: linear-gradient(120deg, #bfbfbd, #f1f1f0 50%, #bfbfbd);
  }
}
.clubB {
  p {
    width: 55px;
    background-image: linear-gradient(150deg, #e77c34, #fac781 50%, #f9e8cb);
  }
  .bordeColor {
    background-image: linear-gradient(
      170deg,
      #f2a661,
      #fcf4e6 35%,
      #f5af65 50%,
      #fcf4e6 75%,
      #f2a661
    );
  }
}
</style>
